<template>
	<div>
		<s-crud
			title="Componentes"
			:config="config"
			:filter="filter"
			add
			search
			edit
			remove
			@save="save($event)"
			load
			@load="load()"
			no-full
			ref="crudComponentes"
		>

			<template v-slot:filter>
				<v-container>
					<v-row>
						<v-col>
							<s-select-definition
								clearable
								label="estado"
								:def="1179"
								v-model="filter.CptStatus"
							></s-select-definition>
						</v-col>
						<v-col>
							<s-select-definition
								clearable
								label="Tipo"
								:def="1311"
								v-model="filter.TypeComponent"
							></s-select-definition>
						</v-col>
						<v-col>
							<s-select-definition
								clearable
								label="Marca"
								:def="1164"
								v-model="filter.TypeBrand"
							></s-select-definition>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template scope="props">
				<v-container>
					<v-row>
						<v-col
							class="s-col-form"
						>
							<s-select-definition
								label="Tipo"
								:def="1311"
								v-model="props.item.TypeComponent"
								@change="changeComponent(props.item.TypeComponent)"
							></s-select-definition>
						</v-col>
						<v-col
							class="s-col-form"
						>
							<s-select
								label="Marca"
								:items="listBrand"
								item-text="TypeBrandName"
								item-value="TypeBrand"
								v-model="props.item.TypeBrand"
							></s-select>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Modelo"
								v-model="props.item.CptModel"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Serie"
								v-model="props.item.CptSerie"
							></s-text>
						</v-col>
						<!-- <v-col class="s-col-form">
							<s-select-definition
								label="Color"
								:def="1166"
								v-model="props.item.TypeColor"
							></s-select-definition>
						</v-col> -->
						<v-col class="s-col-form">
							<s-text
								label="Velocidad (GHz)"
								decimal
								v-model="props.item.CptSppedy"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-select-definition
								label="Adquisición"
								:def="1177"
								v-model="props.item.TypeAcquisition"
							></s-select-definition>
						</v-col>
						<v-col class="s-col-form">
							<s-date
								label="Fecha Adquisición"
								v-model="props.item.DateAcquisition"
							></s-date>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="s-col-form">
							<s-text
								label="Memoria (MB)"
								decimal
								v-model="props.item.CptMemory"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Capacidad (TB)"
								decimal
								v-model="props.item.CptCapacity"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Capacidad2 (TB)"
								decimal
								v-model="props.item.CptCapacity2"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Version"
								decimal
								v-model="props.item.CptVersion"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Controlador"
								decimal
								v-model="props.item.CptController"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Cantidad"
								decimal
								v-model="props.item.CptQuantity"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-select-definition
								label="Estado"
								:def="1179"
								v-model="props.item.CptStatus"
							></s-select-definition>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<s-textarea
								label="Comentario"
								v-model="props.item.CptObservation"
							></s-textarea>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:CptStatus="{ row }">
				<v-chip x-small :color="row.CptStatus == 1 ? 'success' : 'error'">{{ row.CptStatus == 1 ? "Activo" : "Baja" }}</v-chip>
			</template> 

			<template v-slot:CptInUse="{ row }">
				<v-btn
					v-if="row.CptInUse == 0"
					x-small 
					color="success"
					readonly
				>
					<i class="fas fa-desktop"></i>
				</v-btn>
				<v-btn
					v-else
					x-small
					color="warning"
					@click="openDialogAssignament(row)"
				>
					<i class="fas fa-user-lock"></i>
				</v-btn>
			</template>
 
		</s-crud>

		<!-- realizar carga masiva -->
		<v-dialog
			v-if="dialogUploadMassive"
			v-model="dialogUploadMassive"
		>
			<v-card>
			<upload-massive-component
				@closeDialogUploadMassive="closeDialogUploadMassive()"
			></upload-massive-component>
			</v-card>
		</v-dialog>
		
		<!-- datos del usuario asignado -->
		<v-dialog
			v-model="dialogAssignament"
			width="500"
		>
			<v-card dense>
				<s-toolbar
					label="Datos del usuario"
					color="#E0F7F2"
					close
					@close="closeDialogAssignment()"
				></s-toolbar>
				<v-container>
					<v-row>
						<v-col class="s-col-form">
							<s-text
								label="Usuario"
								v-model="worker.NtpFullName"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-text
								label="Cargo"
								v-model="worker.NtpPositionName"
							></s-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
						<s-text
								label="Area"
								v-model="worker.NtpAreaName"
							></s-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import _service from "@/services/InformationTechnology/ComponentService";
	import _serviceBrandComponent from "@/services/InformationTechnology/BrandComponentService";
	import _sAssigDetail from '@/services/InformationTechnology/EquipmentAssignamentDetailService';
	
	import UploadMassiveComponent from './UploadMassiveComponent.vue';

	export default {
		components: {
			UploadMassiveComponent,
		},

		data() {
			return {
				dialogUploadMassive : false,
				worker : {},
				dialogAssignament : false,
				filter: {
					CptStatus : 0,
					TypeBrand : 0,
					TypeComponent : 0
				},
				listBrand: [],
				config: {
					model: {
						CptID: "ID",
						TinDateDelivery: "date",
						TinDateReturn: "date",
						CptStatus: "",
						objcomponent: { DedHelper: 0 },
						CptCapacity: "",
						CptInUse : "",
						DateAcquisition: "date"
					},
					headers: [
						{ text: "ID", value: "CptID" },
						{ text: "Tipo Adquisición", value: "TypeAcquisitionName" },
						{ text: "Fecha Adquisición", value: "DateAcquisition" },
						{ text: "Tipo", value: "TypeComponentName" },
						{ text: "Marca", value: "TypeBrandName" },
						{ text: "Modelo", value: "CptModel" },
						{ text: "Serie", value: "CptSerie" },
						// { text: "Color", value: "TypeColorName" },
						{ text: "Velocidad", value: "CptSppedy" },
						{ text: "SSD", value: "CptCapacity" },
						{ text: "HDD", value: "CptCapacity2" },
						{ text: "Memoria", value: "CptMemory" },
						{ text: "Estado", value: "CptStatus" },
						{ text: "Estado", value: "CptInUse" },
						{ text: "Observación", value: "TinObservation" },
					],
					service: _service,
				},
			}
		},

		methods: {
			closeDialogUploadMassive()
			{
				this.dialogUploadMassive = false;
				this.$refs.crudComponentes.refresh();
			},

			load()
			{
				this.dialogUploadMassive = true;
			},

			closeDialogAssignment()
			{
				this.dialogAssignament = false;
			},

			openDialogAssignament(component)
			{
				let fil = {
					CptID : component.CptID
				}
				_sAssigDetail
				.list(fil, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
					console.log(resp.data);
					if(resp.data.length > 0)
					{
						this.worker.NtpFullName = resp.data[0].NtpFullName;
						this.worker.NtpPositionName = resp.data[0].NtpPositionName;
						this.worker.NtpAreaName = resp.data[0].NtpAreaName;
						this.dialogAssignament = true;
					}
					}
				})
			},

			changeComponent(TypeComponentValue)
			{
				let filter = {
					TypeComponent : TypeComponentValue == undefined ? 1 : TypeComponentValue,
					Search : ''
				}

				_serviceBrandComponent
				.list(filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
					this.listBrand = resp.data;
					console.log('this.listBrand ', this.listBrand);
					}
				})
			},

			save(item)
			{
				if (!item.CptSppedy) item.CptSppedy = 0;
				if (!item.CptCapacity) item.CptCapacity = 0;
				if (!item.TinPrice) item.TinPrice = 0;
				if (!item.CptMemory) item.CptMemory = 0;
				if (!item.CptQuantity) item.CptQuantity = 1;
				item.save();
			}
		},
	}
</script>