<template>
	<div>
		<v-container>
			<s-select-definition
				hidden
				:def="1311"
				@getDataInitial="getTypeComponent($event)"
			></s-select-definition>
		</v-container>

		<s-toolbar
			label="Carga Masiva"
			close
			@close="close()"
			save
			@save="save()"
		></s-toolbar>
		<v-card>
			<v-container>
				<v-row>
					<v-col>
						<s-import-excel
							label="Leer archivo excel"
							@clear="config.excelData = []"
							@changeData="changeFile($event)"
						></s-import-excel>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-data-table
							:headers="config.headers"
							:items="config.excelData"
						></v-data-table>
					</v-col>
				</v-row>
			</v-container>
		</v-card>

		<v-dialog
			v-model="dialogLoading"
			width="400"
			persistent
		>
			<v-card>
				<v-container>
					<v-progress-linear
						indeterminate
						color="green"
					></v-progress-linear>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import _serviceBrandComponent from "@/services/InformationTechnology/BrandComponentService";
	import _service from "@/services/InformationTechnology/ComponentService";

	export default {
		data() {
			return {
				dialogLoading : false,
				listBrandComponent : [],
				listTypeComponent : [],
				config: {
					headers : [
						{text : "Tipo Componente", value : "TypeComponentName"},
						{text : "Marca", value : "TypeBrandName"},
						{text : "Modelo", value : "CptModel"},
						{text : "Serie", value : "CptSerie"},
						{text : "Velocidad", value : "CptSppedy"},
						{text : "Memoria", value : "CptMemory"},
						{text : "SSD", value : "CptCapacity"},
						{text : "HDD", value : "CptCapacity2"},
						{text : "Cantidad", value : "CptQuantity"},
					],
					excelData : []
				}
			}
		},

		methods: {
			save()
			{
				this.$fun.alert("¿Seguro de importar?", "question")
				.then(r => {
					if(r.value)
					{
						if(this.config.excelData.length <= 0)
						{
							this.$fun.alert("Ingrese datos", "warning")
							return;
						}
						console.log(this.config.excelData);
						this.dialogLoading = true;
						_service
						.savemassive(this.config.excelData, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Importación realizada correctamente", "success");
								this.dialogLoading = false;
								this.config.excelData = [];
								return;
							}
							
							this.config.excelData = [];
						})
						.catch(e => {
							this.dialogLoading = false;
						})
					}
				})
			},

			close() {
				this.$emit("closeDialogUploadMassive")
			},

			validatorData(data)
			{
				var resp = {
					erros : 0,
					message : ""
				};
				/* Validando que los tipo de componentes sean correctos */
				var arraySerieUnic = [];
				data.forEach(element => {
					
					var TypeComponent = this.listTypeComponent.find(ele => {
						return element.TipoComponente == ele.DedDescription
					})
					if(TypeComponent != undefined)
					{
						element.TypeComponent = TypeComponent.DedValue;
					}

					var TypeBrand = this.listBrandComponent.find(ele => {
						return element.Marca == ele.TypeBrandName && element.TipoComponente == ele.TypeComponentName
					})
					if(TypeBrand != undefined)
					{
						element.TypeBrand = TypeBrand.TypeBrand;
					}
					if(arraySerieUnic.indexOf(element.Serie) < 0)
					{
						arraySerieUnic.push(element.Serie);
					}
				});

				if(arraySerieUnic.length < data.length)
				{
					resp.erros = 1;
					resp.message = "Existen series repetidas";
				}

				data.forEach(element => {
					if (element.TypeBrand == undefined)
					{
						resp.erros = 1;
						resp.message = "La marca " + element.Marca + " no esta asignado al tipo " + element.TipoComponente;
					}
					if (element.TypeComponent == undefined)
					{
						resp.erros = 1;
						resp.message = "La marca " + element.Marca + " no esta registrada en las configuraciones";
					}
				})

				return resp;
			},

			changeFile(data)
			{
				let resp = this.validatorData(data);
				if (resp.erros > 0)
				{
					this.$fun.alert(resp.message, "warning");
					this.config.excelData = [];
					return;
				}

				let item =  {};
				data.forEach(element => {
					item = {
						TypeComponentName : element.TipoComponente,
						TypeBrandName : element.Marca,
						CptModel : element.Modelo,
						CptSerie : element.Serie,
						CptSppedy : element.Velocidad,
						CptMemory : element.RAM,
						CptCapacity : element.Capacidad,
						CptCapacity2 : element.Capacidad2,
						TypeComponent : element.TypeComponent,
						TypeBrand : element.TypeBrand,
						CptQuantity : element.Cantidad,
						CptStatus : 1,
						SecStatus : 1,
						UsrCreateID : this.$fun.getUserID(),
						UsrUpdateID : this.$fun.getUserID(),
					}
					this.config.excelData.push(item);
				})
			},

			getTypeComponent(data)
			{
				this.listTypeComponent = data;
			},

			getComponentBrand()
			{
				let filter = {
					TypeComponent : 0,
					Search : ""
				}

				_serviceBrandComponent
				.list(filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.listBrandComponent = resp.data;
						console.log('this.listBrandComponent', this.listBrandComponent);
					}
				})
			}
		},
		
		created () {
			this.getComponentBrand();
		},
	}
</script>

